import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useState } from "react";
import Logic from "../../helpers/Logic";
import "./style.css";
import ResolutionBox from "../../apps/shared/components/ResolutionBox";
import { expand, informationCircle, resizeOutline } from "ionicons/icons";
import SharedHeader from "../../apps/shared/components/SharedHeader";
import MyIonInput from "../../apps/shared/components/MyIonInput";
import myUtil from "../../helpers/cha-utils";
import { useHistory } from "react-router";
import SharedContentFooter from "../../apps/shared/components/SharedContentFooter";

export interface IProps {
  [x: string]: {
    input: string; // keep original typing, appears when focus
    show: string; // formatted, appears when blur
    value: number; // parsed Value
  };
}

export const defaultData: IProps = {
  width: { input: "", show: "", value: 1 },
  height: { input: "", show: "", value: 0 },
};

const Index: React.FC = () => {
  const [data, setData] = useState(defaultData);
  // console.log(data);
  // console.log(Logic.calculateAspectRatio(data.width.value, data.height.value));

  let calculatedAspectRatio: { [key: string]: number } | undefined = undefined;
  let calculatedAspectRatioText = "";
  if (data.width.value > 0 && data.height.value > 0) {
    calculatedAspectRatio = Logic.calculateAspectRatio(
      data.width.value,
      data.height.value
    );
    calculatedAspectRatioText =
      calculatedAspectRatio.width + ":" + calculatedAspectRatio.height;
  }

  const handleInputChange = (userKey: string, userValue: any) => {
    let userShow = myUtil.formatNumber(userValue);
    if (userValue == "") userShow = "";

    let newData: IProps = {
      ...data,
      [userKey]: { input: userValue, show: userShow, value: +userValue },
    };

    setData(newData);
  };

  let diagonalValue = undefined;
  if (data.width.value > 0 && data.height.value > 0) {
    diagonalValue =
      myUtil.formatNumber(
        Logic.calculateDiagnal(data.width.value, data.height.value)
      ) + " px";
  }

  const history = useHistory();

  const moreInfo = () => {
    if (calculatedAspectRatio != null) {
      history.push(
        `/apps/as/${calculatedAspectRatio.width}/${calculatedAspectRatio.height}/${data.width.value}`
      );
    }
  };

  return (
    <IonPage className="appPage">
      <SharedHeader
        title="Find Aspect Ratio"
        subTitle="By Resolutions"
        ionIcon={expand}
      />
      <IonContent>
        <IonGrid fixed={true}>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" sizeMd="6">
              <IonCard>
                <IonCardHeader color="primary">
                  <IonCardSubtitle>
                    Please enter width and height to find its aspect ratio
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent className="ion-no-padding">
                  <IonList lines="full" className="ion-no-padding">
                    <IonItem>
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="3" className="ion-align-items-center">
                            <IonLabel>Width</IonLabel>
                          </IonCol>
                          <IonCol>
                            <MyIonInput
                              aria-label="Width"
                              cId="width"
                              data={data.width}
                              onMyChange={handleInputChange}
                            />
                          </IonCol>
                          <IonCol size="auto" className="ion-padding-start">
                            <IonLabel> pixels</IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                    <IonItem lines="none">
                      <IonGrid>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="3" className="ion-align-items-center">
                            <IonLabel>Height</IonLabel>
                          </IonCol>
                          <IonCol>
                            <MyIonInput
                              aria-label="Height"
                              cId="height"
                              data={data.height}
                              onMyChange={handleInputChange}
                            />
                          </IonCol>
                          <IonCol size="auto" className="ion-padding-start">
                            <IonLabel> pixels</IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
              {!diagonalValue && (
                <IonCard>
                  <IonCardHeader color="warning">
                    <IonCardSubtitle>ℹ️ Instructions</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p className="ion-padding-top">
                      To determine an aspect ratio for a screen, image,
                      or video, you will need provides its width and height.</p>
                  </IonCardContent>
                </IonCard>
              )}
            </IonCol>

            <IonCol size="12" sizeMd="6">
              <IonCard>
                <IonCardContent className="ion-padding">
                  <div className="resultBox">
                    <div className="resultSubtitle ion-text-center">
                      Aspect Ratio
                    </div>
                    <div className="resultTitle large">
                      {calculatedAspectRatioText
                        ? calculatedAspectRatioText
                        : "—"}
                      <p>
                        <span className="small">W:H</span>
                      </p>
                    </div>

                    {calculatedAspectRatio && (
                      <div className="ion-text-center">
                        <IonButton onClick={() => moreInfo()}>
                          <IonIcon icon={informationCircle} slot="start" />
                          More Info
                        </IonButton>
                      </div>
                    )}

                    {calculatedAspectRatio && (
                      <>
                        <ResolutionBox
                          rWidth={data.width.value}
                          rHeight={data.height.value}
                          text={diagonalValue}
                        />
                        <div className="ion-text-center">
                          <IonText color="medium">Preview</IonText>
                        </div>
                      </>
                    )}
                    {!calculatedAspectRatio && (
                      <p className="ion-text-center">
                        <IonText color="danger">
                          Please enter valid width and height.
                        </IonText>
                      </p>
                    )}
                  </div>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        <SharedContentFooter
          title="Find Aspect Ratio"
          subTitle="By Resolutions"
          ionIcon={expand}
        />
      </IonContent>
    </IonPage>
  );
};

export default Index;
