import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { HTMLAttributes } from "react";
import style from "./AppListItem.module.css";
import { tabletLandscape } from "ionicons/icons";

interface AppListItemProps extends HTMLAttributes<HTMLIonItemElement> {
  name: string;
  tag: string;
  label: string;
  description?: string;
  imageUrl?: string;
  svgIcon?: any;
  ionIcon?: any;
  ionIconSrc?: string;
  routerLink?: string;
  [x: string]: any;
}

const AppListItem: React.FC<AppListItemProps> = (props) => {
  const { name, label, description, imageUrl, ...rest } = props;
  const imgUrl = imageUrl ? imageUrl : `/assets/icons/${name}/icon.png`;
  const routerLink = props.routerLink ? props.routerLink : `/app/${name}`;

  const tagItems = props.tag ? props.tag.split(",") : [props.tag];
  const tagItemsEl = tagItems.map((item) => (
    <span className={style.tagItem} key={item}>
      {item.toLowerCase()}
    </span>
  ));

  let iconWrapperClasses = style.iconWrapper;
  if (imageUrl) iconWrapperClasses += " " + style.img;
  else if (props.ionIcon) iconWrapperClasses += " " + style.ionIcon;
  else iconWrapperClasses += " " + style.svg;
  return (
    <IonItem
      lines="none"
      routerLink={routerLink}
      {...rest}
      className={style.appItem}
    >
      <div className={iconWrapperClasses}>
        {props.ionIcon && <IonIcon icon={props.ionIcon} />}
        {props.ionIconSrc && <IonIcon src={props.ionIconSrc} />}
        {imageUrl && <img alt={label} src={imageUrl} />}
      </div>
      <IonLabel>
        {props.tag && <div className={style.tag}>{tagItemsEl}</div>}
        <h2 className={style.appTitle}>{label}</h2>
        <p className={style.description}>{description}</p>
      </IonLabel>
    </IonItem>
  );
};

export default AppListItem;
