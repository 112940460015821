import React from "react";
// import AdsComponent from '../../../components/AdsComponent';
import style from "./SharedContentFooter.module.css";
import { IonIcon } from "@ionic/react";
import myUtil, { IS_EMBED } from "../../../helpers/cha-utils";

interface IProps {
  onRefresh?: () => void;
  title: string;
  subTitle?: string;
  imageUrl?: string;
  svgIcon?: any;
  ionIcon?: any;
  ionIconSrc?: string;
  imageIcon?: boolean;
}
const SharedContentFooter: React.FC<IProps> = (props) => {
  return (
    <>
      {/* <AdsComponent /> */}
      {!IS_EMBED && (
        <>
          <div className={style.footer}>
            <div className={`${style.iconWrapper} ${props.imageIcon && "img"}`}>
              {props.ionIcon && <IonIcon icon={props.ionIcon} />}
              {props.ionIconSrc && <IonIcon src={props.ionIconSrc} />}
              {props.imageUrl && <img alt={props.title} src={props.imageUrl} />}
            </div>
            Powered by{" "}
            <a href="https://calculatorhub.app" target="_blank">
              CalculatorHub.app
            </a>
          </div>
          <div className={style.footerSpacer}></div>
        </>
      )}
    </>
  );
};

export default SharedContentFooter;
