import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { RouteComponentProps, useHistory } from "react-router";

import myUtil from "../../helpers/cha-utils";
import React, { useEffect, useRef, useState } from "react";
import { reloadOutline, settingsOutline, swapHorizontal } from "ionicons/icons";

import "../../theme/table.css";
import classes from "./index.module.css";
import Logic from "../../helpers/Logic";
import BasicTextBox from "../../components/BasicTextBox";
import Settings from "../../components/Footer/Settings";
import ControlItemType from "../../types/ControlItemType";
import { UrlDB } from "../../db/url-db";
import { ButtonExplain } from "../../components/ButtonExplain";
interface ConverterProps
  extends RouteComponentProps<{
    asWidth: string;
    asHeight: string;
    width: string;
  }> { }

type asType = {
  asWidth: number;
  asHeight: number;
  width: number;
  height: number;
};

const asList: asType[] = [
  // { asWidth: 1, asHeight: 1, width: 1080, height: 1080 },
  // { asWidth: 3, asHeight: 2, width: 1680, height: 1080 },
  // { asWidth: 4, asHeight: 3, width: 1440, height: 1080 },
  // { asWidth: 5, asHeight: 4, width: 1350, height: 1080 },
  // { asWidth: 16, asHeight: 9, width: 1920, height: 1080 },
  // { asWidth: 16, asHeight: 10, width: 1728, height: 1080 },
  // { asWidth: 21, asHeight: 9, width: 2520, height: 1080 },
  // { asWidth: 32, asHeight: 9, width: 3840, height: 1080 },
  { asWidth: 2, asHeight: 3, width: 1080, height: 1680 },
  { asWidth: 3, asHeight: 4, width: 1080, height: 1440 },
  { asWidth: 4, asHeight: 5, width: 1080, height: 1350 },
  { asWidth: 9, asHeight: 16, width: 1080, height: 1920 },
];

const Index: React.FC<ConverterProps> = ({ match }) => {
  const [decimalValue, setDecimalValue] = useState<number>(2);

  // console.log(match.params);

  let selectAs: asType;
  if (match.params.width) {
    selectAs = {
      asWidth: +match.params.asWidth,
      asHeight: +match.params.asHeight,
      width: +match.params.width,
      height: Logic.calculateHeight(
        +match.params.width,
        +match.params.asWidth,
        +match.params.asHeight
      ),
    };
  } else {
    selectAs = asList.find(
      (item) =>
        item.asWidth === +match.params.asWidth &&
        item.asHeight === +match.params.asHeight
    ) ?? {
      asWidth: +match.params.asWidth,
      asHeight: +match.params.asHeight,
      width: Logic.calculateWidth(
        1080,
        +match.params.asWidth,
        +match.params.asHeight
      ),
      height: 1080,
    };
  }
  // console.log(Logic.calculateWidth(1080, +match.params.asWidth, +match.params.asHeight), +match.params.asWidth, +match.params.asHeight);
  // console.log(selectAs);
  let pageTitle =
    selectAs.asWidth + ":" + selectAs.asHeight + " Aspect Ratio Calculator";

  const modal = useRef<HTMLIonModalElement>(null);
  const customASmodal = useRef<HTMLIonModalElement>(null);

  const initialControlItems: ControlItemType[] = [
    {
      key: "width",
      input: myUtil.formatNumber(selectAs.width, decimalValue),
      value: selectAs.width,
      label: "Width",
      sublabel: "Pixel",
      short: "px",
    },
    {
      key: "height",
      input: myUtil.formatNumber(selectAs.height, decimalValue),
      value: selectAs.height,
      label: "Height",
      sublabel: "Pixel",
      short: "px",
    },
    {
      key: "size",
      input: "21.0",
      value: 21,
      label: "Size",
      sublabel: "Inches",
      short: "in",
    },
  ];

  // console.log(initialControlItems);

  const [controlItems, setControlItems] =
    useState<ControlItemType[]>(initialControlItems);

  useEffect(() => {
    setControlItems(initialControlItems);
  }, [selectAs.asWidth, selectAs.asHeight]);

  const clearButtonHandler = () => {
    setControlItems(initialControlItems);
  };

  const inputChangeHandler = (
    controller: ControlItemType,
    user_input: string,
    user_value: number
  ) => {
    setControlItems((oldItems) => {
      const [oW, oH, oS] = oldItems.map((item) => {
        if (item.key === controller.key) {
          item.input = user_input;
          item.value = user_value;
        }
        return item;
      });

      switch (controller.key) {
        case "width":
          oH.value = myUtil.prepareFloatingNumber(
            Logic.calculateHeight(
              user_value,
              selectAs.asWidth,
              selectAs.asHeight
            ),
            decimalValue
          );
          oH.input = myUtil.formatNumber(oH.value, decimalValue);
          break;
        case "height":
          oW.value = myUtil.prepareFloatingNumber(
            Logic.calculateWidth(
              user_value,
              selectAs.asWidth,
              selectAs.asHeight
            ),
            decimalValue
          );
          oW.input = myUtil.formatNumber(oW.value, decimalValue);
          break;
        case "size":
          break;
      }
      return [oW, oH, oS];
    });
  };

  const controlItemsJSX = controlItems.map((item) => (
    // <IonCol sizeSm={item.key === "size" ? "12" : "6"} sizeXs="12" sizeMd="4" key={item.key}>
    <IonCol size="12" key={item.key}>
      <BasicTextBox
        controller={item}
        type="text"
        label={item.label}
        subLabel={item.sublabel}
        short={item.short}
        shortWidth={32}
        textSize={3}
        input={item.input}
        value={item.value}
        onIonInput={inputChangeHandler}
        decimalValue={decimalValue}
        key={item.key}
      />
    </IonCol>
  ));

  const resolutions: { width: number; height: number; desc?: string }[] = [];
  let percentage = 0;
  const rWidth = controlItems.find((item) => item.key === "width")!.value;
  const rHeight = controlItems.find((item) => item.key === "height")!.value;
  const rSize = controlItems.find((item) => item.key === "size")!.value;
  const rDiagnal = Math.sqrt(rWidth * rWidth + rHeight * rHeight);
  let diagnalAngle = (Math.asin(rHeight / rDiagnal) * 180) / Math.PI;

  for (let i = 1; i <= 10000; i++) {
    percentage = (selectAs.asWidth * i * 100) / rWidth;
    // console.log(percentage % 5);
    if (percentage % 5 === 0)
      resolutions.push({
        desc: percentage + "%",
        width: selectAs.asWidth * i,
        height: selectAs.asHeight * i,
      });
    if (percentage >= 200) break;
  }
  let template: any = resolutions.map((item) => (
    <tr key={item.width.toString()}>
      <td>{item.desc}</td>
      <td>
        {item.width.toString()} x {item.height.toString()}
      </td>
      <td>{myUtil.formatNumber(item.width * item.height)} px</td>
    </tr>
  ));

  if (resolutions.length === 0) {
    template = (
      <tr>
        <td colSpan={3}>
          We can't find perfect scaling for this resolution. Please try another
          one!
        </td>
      </tr>
    );
  }

  const screenWidth = Logic.calculatePhysicalWidth(
    rSize,
    selectAs.asWidth,
    selectAs.asHeight
  );
  const screenHeight = Logic.calculatePhysicalHeight(
    rSize,
    selectAs.asWidth,
    selectAs.asHeight
  );

  const screenSizes = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 32, 34, 40, 42, 43, 48, 50, 55, 58, 60,
    65, 70, 75, 77, 83, 85, 86, 97, 98,
  ];

  let uwAsTSX = null;
  const uwAsItems = ["21:9", "12:5", "43:18", "64:27"];
  const history = useHistory();
  if (uwAsItems.indexOf(selectAs.asWidth + ":" + selectAs.asHeight) > -1) {
    uwAsTSX = (
      <IonRow>
        <IonCol>
          <p>
            Please choose an aspect ratio that matches your display. Most
            companies marketed the following aspect ratios as 21:9.
          </p>
          <div>
            {[
              [21, 9],
              [12, 5],
              [43, 18],
              [64, 27],
            ].map((item) => {
              const rPath = "/apps/as/" + item[0] + "/" + item[1];
              const rName = item[0] + ":" + item[1];
              return (
                <IonButton
                  onClick={() => history.replace(rPath, null)}
                  size="small"
                  key={rName}
                  fill={
                    selectAs.asWidth == item[0] && selectAs.asHeight == item[1]
                      ? "solid"
                      : "outline"
                  }
                >
                  {rName}
                </IonButton>
              );
            })}
          </div>
        </IonCol>
      </IonRow>
    );
  }
  let btnGuide = null;
  const path = selectAs.asWidth + ":" + selectAs.asHeight;
  if (Object.keys(UrlDB).find((item) => item == path)) {
    btnGuide = (
      <div>
        <ButtonExplain name={UrlDB[path]} />
        <p className="ion-text-right">
          What is {path} aspect ratio? Explanation available for this aspect
          ratio.{" "}
        </p>
      </div>
    );
  }

  return (
    <>
      {/* <Sidebar /> */}
      <IonPage id="main-content" className={classes.page}>
        <IonHeader color="primary">
          <IonToolbar className={classes.toolbar}>
            <IonButtons slot="start">
              <IonBackButton></IonBackButton>
            </IonButtons>
            <IonTitle>{pageTitle}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={clearButtonHandler}>
                <IonIcon icon={reloadOutline}></IonIcon>
              </IonButton>
              <IonButton onClick={() => modal.current?.present()}>
                <IonIcon icon={settingsOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding" fullscreen={true}>
          <IonGrid fixed={true}>
            <IonRow>
              <IonCol size="12" sizeMd="6">
                <IonGrid>
                  {uwAsTSX}
                  <IonRow>{controlItemsJSX}</IonRow>
                  <IonRow className="ion-align-items-center">
                    <IonCol>
                      Current: {selectAs.asWidth}:{selectAs.asHeight}
                    </IonCol>
                    <IonCol className="ion-text-right" size="auto">
                      <IonButton
                        shape="round"
                        size="small"
                        // onClick={() => customASmodal.current?.present()}
                        routerLink={`/app/custom-aspect-ratio`}
                      >
                        <IonIcon icon={swapHorizontal} slot="start" />
                        Change Aspect Ratio
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <div className={classes.tvBox}>
                  <div
                    className={classes.viewBox}
                    style={{
                      borderRadius: "4px",
                      // width: "100%",
                      maxWidth: "90%",
                      maxHeight: "350px",
                      marginTop: "20px",
                      aspectRatio: selectAs.asWidth + "/" + selectAs.asHeight,
                      position: "relative",
                    }}
                  >
                    <div className={classes.viewBoxBackground}></div>
                    <div
                      className={classes.diagonalLine}
                      style={{ transform: "rotate(-" + diagnalAngle + "deg)" }}
                    ></div>

                    <span
                      style={{
                        position: "absolute",
                        top: 0,
                        left: "50%",
                        transform: "translate(-50%, 0)",
                      }}
                    >
                      {myUtil.formatNumber(screenWidth, 2)}″
                    </span>
                    <span
                      style={{
                        position: "absolute",
                        left: 2,
                        top: "50%",
                        transform: "translate(0,-50%)",
                      }}
                    >
                      {myUtil.formatNumber(screenHeight, 2)}″
                    </span>
                    <span
                      style={{
                        position: "absolute",
                        bottom: 2,
                        left: "50%",
                        transform: "translate(-50%, 0)",
                      }}
                    >
                      {myUtil.formatNumber(rWidth, 2)} px
                    </span>
                    <span
                      style={{
                        position: "absolute",
                        right: 2,
                        top: "50%",
                        transform: "translate(0,-50%)",
                      }}
                    >
                      {myUtil.formatNumber(rHeight, 2)} px
                    </span>
                    <div
                      className={classes.diagnalText}
                      style={{
                        transform:
                          "rotate(-" +
                          diagnalAngle +
                          "deg) translate(-50%,-50%)",
                      }}
                    >
                      {rSize}″
                    </div>
                  </div>
                  <div className={classes.tvLeg}></div>
                  <div className={classes.tvStand}></div>
                </div>
                {btnGuide}
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid fixed={true} className="ion-no-padding">
            <IonRow>
              <IonCol size="12" sizeMd="6">
                <IonCard>
                  <IonCardHeader color="primary">
                    <IonCardSubtitle>Resolution information</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <div className="table-theme-2 ion-margin-top">
                      <table>
                        <tbody>
                          <tr>
                            <td width={140}>Aspect Ratio</td>
                            <td>
                              <strong>
                                {selectAs.asWidth}:{selectAs.asHeight}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td>Horizontal</td>
                            <td>
                              <strong>{rWidth}</strong> pixels
                            </td>
                          </tr>
                          <tr>
                            <td>Vertical</td>
                            <td>
                              <strong>{rHeight}</strong> pixels
                            </td>
                          </tr>
                          <tr>
                            <td>Formula</td>
                            <td>
                              Width / Height ={" "}
                              <strong>
                                {myUtil.formatNumber(
                                  selectAs.asWidth / selectAs.asHeight,
                                  decimalValue
                                )}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td>Pixel Count</td>
                            <td>
                              <strong>
                                {myUtil.formatNumber(rWidth * rHeight)}
                              </strong>{" "}
                              pixels
                            </td>
                          </tr>
                          <tr>
                            <td>Mega Pixels</td>
                            <td>
                              <strong>
                                {myUtil.formatNumber(
                                  (rWidth * rHeight) / 1000000
                                )}
                              </strong>{" "}
                              MP
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <IonCard>
                  <IonCardHeader color="primary">
                    <IonCardSubtitle>Sizing and pixel density</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <div className="table-theme-2 ion-margin-top">
                      <table>
                        <tbody>
                          <tr>
                            <td width={140}>Screen diagonal</td>
                            <td>
                              <strong>{rSize}</strong> inches {" | "}
                              <strong>{rSize * 2.54}</strong> cm
                            </td>
                          </tr>
                          <tr>
                            <td>Pixels per inch</td>
                            <td>
                              <strong>
                                {myUtil.formatNumber(
                                  Logic.calculatePPI(rWidth, rHeight, rSize),
                                  decimalValue
                                )}
                              </strong>{" "}
                              PPI
                            </td>
                          </tr>
                          <tr>
                            <td>Screen width</td>
                            <td>
                              <strong>
                                {myUtil.formatNumber(screenWidth, decimalValue)}
                              </strong>{" "}
                              inches {" | "}
                              <strong>
                                {myUtil.formatNumber(
                                  screenWidth * 2.54,
                                  decimalValue
                                )}
                              </strong>{" "}
                              cm
                            </td>
                          </tr>
                          <tr>
                            <td>Screen height</td>
                            <td>
                              <strong>
                                {myUtil.formatNumber(
                                  screenHeight,
                                  decimalValue
                                )}
                              </strong>{" "}
                              inches {" | "}
                              <strong>
                                {myUtil.formatNumber(
                                  screenHeight * 2.54,
                                  decimalValue
                                )}
                              </strong>{" "}
                              cm
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid fixed={true} className="ion-no-padding">
            <IonRow>
              <IonCol size="12" sizeMd="6">
                <IonCard>
                  <IonCardHeader color="primary">
                    <IonCardSubtitle>Scaling resolutions</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <div
                      className="table-theme-2 ion-margin-top"
                      style={{ maxHeight: "500px" }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>Scaling</th>
                            <th>Resolution</th>
                            <th>Pixel count</th>
                          </tr>
                        </thead>
                        <tbody>{template}</tbody>
                      </table>
                    </div>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <IonCard>
                  <IonCardHeader color="primary">
                    <IonCardSubtitle>Screen sizing</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <div
                      className="table-theme-2 lg ion-margin-top"
                      style={{ maxHeight: "500px" }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>Diagonal</th>
                            <th>Width</th>
                            <th>Height</th>
                            <th>Pixels per inch</th>
                          </tr>
                        </thead>
                        <tbody>
                          {screenSizes.map((item) => {
                            const cmD = item * 2.54;
                            const inW = Logic.calculatePhysicalWidth(
                              item,
                              selectAs.asWidth,
                              selectAs.asHeight
                            );
                            const cmW = inW * 2.54;
                            let inH = Logic.calculatePhysicalHeight(
                              item,
                              selectAs.asWidth,
                              selectAs.asHeight
                            );
                            const cmH = inH * 2.54;
                            return (
                              <tr key={item}>
                                <td>
                                  {item}″<br />
                                  {myUtil.formatNumber(cmD, decimalValue)} cm
                                </td>
                                <td>
                                  {myUtil.formatNumber(inW, decimalValue)}″
                                  <br />
                                  {myUtil.formatNumber(cmW, decimalValue)} cm
                                </td>
                                <td>
                                  {myUtil.formatNumber(inH, decimalValue)}″
                                  <br />
                                  {myUtil.formatNumber(cmH, decimalValue)} cm
                                </td>
                                <td>
                                  {myUtil.formatNumber(
                                    Logic.calculatePPI(rWidth, rHeight, item),
                                    decimalValue
                                  )}{" "}
                                  ppi
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonModal
            ref={modal}
            initialBreakpoint={1}
            breakpoints={[0, 0.9, 1]}
          >
            <Settings
              decimalValue={decimalValue}
              onRangeUpdated={(v) => setDecimalValue(+v)}
              onClose={() => modal.current?.dismiss()}
            />
          </IonModal>

        </IonContent>
      </IonPage >
    </>
  );
};

export default Index;
