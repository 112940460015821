import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRange,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import "../shared/css/AppHeader.css";
import "../shared/css/ResultBox.css";
import Logic, { IAspectRatioInfo, asInfos } from "./Logic";
import myUtil from "../../helpers/cha-utils";
import MyIonInput from "../shared/components/MyIonInput";
import "../../theme/table.css";
import SharedLogic from "../shared/SharedLogic";
import SharedContentFooter from "../shared/components/SharedContentFooter";
import SharedHeader from "../shared/components/SharedHeader";
import {
  informationCircle,
  tabletLandscapeOutline,
  trashBinOutline,
} from "ionicons/icons";
import ResolutionBox from "../shared/components/ResolutionBox";
import { useHistory } from "react-router";

const Index: React.FC = () => {
  const [data, setData] = useState(Logic.getDefaultData());
  const [preset, setPreset] = useState("");
  const [isCustomAS, setIsCustomAS] = useState(true);

  const presetData: IAspectRatioInfo | null = preset
    ? asInfos.find((item) => item.key === preset) ?? asInfos[0]
    : null;

  useEffect(() => {
    applyPreset();
    console.log("Apply Preset: " + preset);
  }, [preset]);

  const applyPreset = () => {
    if (presetData) {
      let newData = { ...data };
      Logic.setDataItemValue(newData.asWidth, presetData.width);
      Logic.setDataItemValue(newData.asHeight, presetData.height);
      autoCalculate(newData, "asWidth");
      setData(newData);
      setIsCustomAS(false);
    }
  };

  const handleInputChange = (dataKey: string, userValue: any) => {
    let userShow = myUtil.formatNumber(userValue);
    if (userValue == "") userShow = "";
    let nativeValue = +userValue;

    const dataItemKey = dataKey as keyof typeof data;
    const newData = { ...data, [dataKey]: { ...data[dataItemKey] } };

    const newDataItem = newData[dataItemKey];

    newDataItem.input = userValue;
    newDataItem.show = myUtil.formatNumber(userValue);
    newDataItem.value = nativeValue;

    autoCalculate(newData, dataKey);

    setData(newData);
  };

  const autoCalculate = (newData: typeof data, dataKey: string) => {
    if (data.asWidth.value && data.asHeight.value) {
      let newWidth,
        newHeight = 0;
      switch (dataKey) {
        case "asWidth":
        case "asHeight":
        case "width":
          newHeight = SharedLogic.calculateHeight(
            newData.width.value,
            newData.asWidth.value,
            newData.asHeight.value
          );
          Logic.setDataItemValue(newData.height, newHeight);
          break;
        case "height":
          newWidth = SharedLogic.calculateWidth(
            newData.height.value,
            newData.asWidth.value,
            newData.asHeight.value
          );
          Logic.setDataItemValue(newData.width, newWidth);
          break;
      }

      // Logic.setDataItemValue(
      //   // newData.area,
      //   newData.width.value * newData.height.value
      // );
    }
  };

  const asInfoJSX = asInfos.map((item) => (
    <IonSelectOption key={item.key} value={item.key}>
      {item.name}
    </IonSelectOption>
  ));

  let diagonalValue = undefined;
  if (data.width.value > 0 && data.height.value > 0) {
    diagonalValue =
      myUtil.formatNumber(
        Logic.calculateDiagnal(data.width.value, data.height.value)
      ) + " px";
  }

  const history = useHistory();

  const moreInfo = () => {
    if (!data.width.value) return;
    history.push(
      `/apps/as/${data.asWidth.value}/${data.asHeight.value}/${data.width.value}`
    );
  };


  return (
    <IonPage className="appPage">
      <SharedHeader
        title="Find Resolution"
        subTitle="Calculator"
        ionIcon={tabletLandscapeOutline}
      />
      {/* <IonContent fullscreen scrollEvents={true} onIonScroll={(e) => handleContentScroll(e.detail.scrollTop)}> */}
      <IonContent fullscreen={true} id="mainContent">
        {/* Show Header only when in app mode */}
        {/* {SharedConfig.isShowHeader && <AppHeader title="Waist-Hip Ratio" subTitle="WHR Calculator" ionIconSrc="/assets/icons/ppi/icon.svg" />} */}
        <IonGrid fixed>
          <IonRow>
            <IonCol size="12" sizeMd="6">
              <IonCard>
                <IonCardHeader color="primary">
                  <IonCardSubtitle>
                    Step 1. Choose or enter an aspect ratio{" "}
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent className="ion-no-padding">
                  <div className="ion-padding">
                    {[
                      "16:9",
                      "16:10",
                      "21:9",
                      "32:9",
                      "3:2",
                      "4:3",
                      "5:4",
                      "1:1",
                    ].map((item) => (
                      <IonButton
                        onClick={() => setPreset(item)}
                        fill={preset == item ? "solid" : "outline"}
                        size="small"
                        key={item}
                      >
                        {item}
                      </IonButton>
                    ))}
                    <IonButton
                      onClick={() => {
                        setIsCustomAS(true);
                        setPreset("");
                      }}
                      fill={preset == "" ? "solid" : "outline"}
                      size="small"
                    >
                      Custom
                    </IonButton>
                  </div>

                  <IonList className="ion-no-padding" lines="none">
                    {isCustomAS && (
                      <IonItem>
                        <IonGrid className="ion-no-padding">
                          <IonRow className="ion-align-items-top">
                            <IonCol className="expandableRange">
                              <MyIonInput
                                aria-label="AS Width"
                                cId="asWidth"
                                data={data.asWidth}
                                onMyChange={handleInputChange}
                                fill="outline"
                              />
                              <IonRange
                                aria-label="asWidth"
                                min={0}
                                max={32}
                                value={data.asWidth.value}
                                snaps={true}
                                onIonInput={(e: any) =>
                                  handleInputChange("asWidth", e.detail.value)
                                }
                              />
                            </IonCol>
                            <IonCol
                              size="auto"
                              className="ion-padding-horizontal"
                              style={{ paddingTop: "17px" }}
                            >
                              <IonLabel>:</IonLabel>
                            </IonCol>
                            <IonCol className="expandableRange">
                              <MyIonInput
                                aria-label="AS Height"
                                cId="asHeight"
                                data={data.asHeight}
                                onMyChange={handleInputChange}
                                fill="outline"
                              />
                              <IonRange
                                aria-label="asHeight"
                                min={0}
                                max={32}
                                snaps={true}
                                value={data.asHeight.value}
                                onIonInput={(e) =>
                                  handleInputChange("asHeight", e.detail.value)
                                }
                              />
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol></IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonItem>
                    )}
                    <IonItem>
                      <IonSelect
                        value={preset}
                        interface="alert"
                        aria-label="Screen Info"
                        selectedText={presetData && presetData.name}
                        onIonChange={(e) => setPreset(e.detail.value)}
                        label="Presets"
                        placeholder="Select Here"
                        labelPlacement="start"
                      >
                        {asInfoJSX}
                      </IonSelect>
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardHeader color="primary">
                  <IonCardSubtitle>
                    Step 2: Enter width to find height or vice versa
                  </IonCardSubtitle>
                </IonCardHeader>

                <IonList lines="full" className="ion-no-padding">
                  {/* Screen Width */}
                  <IonItem>
                    <IonGrid className="expandableRange ion-no-padding">
                      <>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="auto">
                            <IonLabel>
                              Width {SharedLogic.checkRequired(data.width.value)}
                            </IonLabel>
                          </IonCol>
                          <IonCol>
                            <MyIonInput
                              aria-label="Width"
                              cId="width"
                              data={data.width}
                              onMyChange={handleInputChange}
                            />
                          </IonCol>
                          <IonCol size="auto" className="ion-padding-start">
                            pixels
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonRange
                              aria-label="Width"
                              min={0}
                              max={5000}
                              value={+data.width.input}
                              onIonInput={(e) =>
                                handleInputChange("width", e.detail.value)
                              }
                            />
                          </IonCol>
                        </IonRow>
                      </>
                    </IonGrid>
                  </IonItem>

                  {/* Screen Height */}
                  <IonItem>
                    <IonGrid className="expandableRange ion-no-padding">
                      <>
                        <IonRow className="ion-align-items-center">
                          <IonCol size="auto">
                            <IonLabel>Height {SharedLogic.checkRequired(data.height.value)}</IonLabel>
                          </IonCol>
                          <IonCol>
                            <MyIonInput
                              aria-label="Height"
                              cId="height"
                              data={data.height}
                              onMyChange={handleInputChange}
                            />
                          </IonCol>
                          <IonCol size="auto" className="ion-padding-start">
                            pixels
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonRange
                              aria-label="Height"
                              min={0}
                              max={5000}
                              value={+data.height.input}
                              onIonInput={(e) =>
                                handleInputChange("height", e.detail.value)
                              }
                            />
                          </IonCol>
                        </IonRow>
                      </>
                    </IonGrid>
                  </IonItem>
                  <IonItem lines="none">
                    <IonButton
                      fill="solid"
                      color="danger"
                      onClick={() => {
                        setData(Logic.getDefaultData());
                        setIsCustomAS(true);
                        setPreset("");
                      }}
                      aria-label="Clear"
                      title="Clear"
                      className="ion-float-right"
                      style={{ marginRight: "8px" }}
                      slot="end"
                    >
                      <IonIcon icon={trashBinOutline} slot="start" />
                      Clear
                    </IonButton>
                  </IonItem>
                </IonList>
              </IonCard>
            </IonCol>
            <IonCol size="12" sizeMd="6">
              <IonCard>
                <IonCardContent className="ion-padding">
                  <div className="resultBox">
                    <div className="resultSubtitle ion-text-center">
                      Resolution
                    </div>
                    <div className="resultTitle small">
                      {diagonalValue
                        ? myUtil.formatNumber(data.width.value) +
                          " x " +
                          myUtil.formatNumber(data.height.value)
                        : "—"}
                      <p>
                        <span className="small">W x H</span>
                      </p>
                    </div>

                    {diagonalValue && (
                      <div className="ion-text-center">
                        <IonButton onClick={() => moreInfo()}>
                          <IonIcon icon={informationCircle} slot="start" />
                          More Info
                        </IonButton>
                      </div>
                    )}

                    {diagonalValue && (
                      <>
                        <ResolutionBox
                          rWidth={data.width.value}
                          rHeight={data.height.value}
                          text={diagonalValue}
                        />
                        <div className="ion-text-center">
                          <IonText color="medium">Preview</IonText>
                        </div>
                      </>
                    )}
                  </div>
                </IonCardContent>
              </IonCard>

              {!diagonalValue && (
                <IonCard>
                  <IonCardHeader color="warning">
                    <IonCardSubtitle>ℹ️ Instructions</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <p className="ion-padding-top">
                      To determine the correct resolution for a screen, image,
                      or video, please follow these steps:
                    </p>
                    <ol>
                      <li>
                        Select an aspect ratio from the available presets, or
                        use the "Custom" button to enter it manually.
                      </li>
                      <li>
                        Enter one of the dimensions, either the width or the
                        height. The other dimension will be calculated
                        automatically.
                      </li>
                    </ol>
                  </IonCardContent>
                </IonCard>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <SharedContentFooter
          title="Find Resolution"
          subTitle="Calculator"
          ionIcon={tabletLandscapeOutline}
        />
      </IonContent>
    </IonPage>
  );
};

export default Index;
