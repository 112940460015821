import {
  AdMob,
  BannerAdOptions,
  BannerAdSize,
  BannerAdPosition,
  BannerAdPluginEvents,
  AdMobBannerSize,
  AdmobConsentStatus,
} from "@capacitor-community/admob";
import { Capacitor } from "@capacitor/core";

namespace AdMobService {
  let bannerHeight = 0;
  const options: BannerAdOptions = {
    // adId: "ca-app-pub-3940256099942544/9214589741", // Test ID
    adId: "ca-app-pub-7215309214458962/6280120628", // Real ID
    adSize: BannerAdSize.ADAPTIVE_BANNER,
    position: BannerAdPosition.BOTTOM_CENTER,
    // margin: 56,  //for Tabs
    margin: 0,
    // isTesting: true,
    // npa: true,
  };

  export const autoLoadBanner = async () => {
    async function banner(): Promise<void> {
      AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
        // Subscribe Banner Event Listener
      });

      AdMob.addListener(
        BannerAdPluginEvents.SizeChanged,
        (size: AdMobBannerSize) => {
          bannerHeight = size.height;
          // Subscribe Change Banner Size
        }
      );

      AdMob.showBanner(options);

      window.addEventListener("orientationchange", () => {
        AdMob.removeBanner().then(() => {
          AdMob.showBanner(options);
        });
        console.log("Orienation App: ", window.screen.orientation.type);
      });
    }

    if (Capacitor.isNativePlatform()) {
      // AdMob.initialize({
      //   requestTrackingAuthorization: true,
      //   // testingDevices: [""],
      //   // initializeForTesting: true,
      // });

      initialize();

      banner();
    }
  };

  export const adjustAppMargin = () => {
    document.querySelector("ion-router-outlet");
    const app: HTMLIonRouterOutletElement | null =
      document.querySelector("ion-router-outlet");
    app!.style.marginBottom = bannerHeight + "px";
  };

  export async function initialize(): Promise<void> {
    await AdMob.initialize();

    const [trackingInfo, consentInfo] = await Promise.all([
      AdMob.trackingAuthorizationStatus(),
      AdMob.requestConsentInfo(),
    ]);

    if (trackingInfo.status === "notDetermined") {
      /**
       * If you want to explain TrackingAuthorization before showing the iOS dialog,
       * you can show the modal here.
       * ex)
       * const modal = await this.modalCtrl.create({
       *   component: RequestTrackingPage,
       * });
       * await modal.present();
       * await modal.onDidDismiss();  // Wait for close modal
       **/

      await AdMob.requestTrackingAuthorization();
    }

    const authorizationStatus = await AdMob.trackingAuthorizationStatus();
    if (
      authorizationStatus.status === "authorized" &&
      consentInfo.isConsentFormAvailable &&
      consentInfo.status === AdmobConsentStatus.REQUIRED
    ) {
      await AdMob.showConsentForm();
    }
  }
}

export default AdMobService;
