import { IonButton, IonCol, IonGrid, IonRow, IonSearchbar } from "@ionic/react";
import AppListItem from "./AppListItem";
import { appListItems, tagItems } from "../db/Data";
import style from "./AppList.module.css";
import { useEffect, useState } from "react";

interface AppListProps {
  [x: string]: any;
}

const AppList: React.FC<AppListProps> = ({ ...rest }) => {
  let appId = 1;
  let [results, setResults] = useState(appListItems);
  let [search, setSearch] = useState("");
  let [tag, setTag] = useState("");

  useEffect(() => {
    updateResults();
  }, [search, tag]);

  const handleInput = (ev: Event) => {
    let query = "";
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();
    setSearch(query);
  };

  const updateResults = () => {
    setResults(
      appListItems.filter((d) => {
        if (tag === "") {
          if (d.label.toLowerCase().indexOf(search) > -1) return true;
        } else {
          if (d.tag!.toLowerCase().indexOf(tag.toLocaleLowerCase()) > -1)
            if (d.label.toLowerCase().indexOf(search) > -1) return true;
        }
      })
    );
  };

  const filterTagEls = tagItems.map((item) => (
    <IonButton
      onClick={() => setTag(item)}
      fill={tag == item ? "solid" : "outline"}
      size="small"
      key={item}
    >
      {item}
    </IonButton>
  ));
  const filterEl = (
    <div>
      <IonButton
        onClick={() => setTag("")}
        fill={tag == "" ? "solid" : "outline"}
        size="small"
        key={"all"}
      >
        All
      </IonButton>
      {filterTagEls}
    </div>
  );

  // console.log(results);

  return (
    <IonGrid fixed {...rest}>
      {/* <IonRow className="ion-justify-content-center ion-margin-bottom">
        <IonCol size="12" sizeSm="8" sizeMd="6">
          <IonSearchbar debounce={500} onIonInput={(ev) => handleInput(ev)} />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-margin scroll-x">{filterEl}</IonCol>
      </IonRow> */}
      <IonRow>
        {results.length > 0 ? (
          results.map((app) => (
            <IonCol
              sizeXs="12"
              sizeSm="6"
              sizeMd="6"
              sizeLg="4"
              sizeXl="4"
              className={style.appItem}
              key={appId++}
            >
              <AppListItem
                name={app.name}
                label={app.label}
                tag={app.tag}
                description={app.description}
                imageUrl={app.imageUrl}
                svgIcon={app.svgIcon}
                ionIconSrc={app.ionIconSrc}
                ionIcon={app.ionIcon}
              />
            </IonCol>
          ))
        ) : (
          <IonCol className="ion-text-center">No app found</IonCol>
        )}
      </IonRow>
    </IonGrid>
  );
};

export default AppList;
