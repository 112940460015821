import { Route } from "react-router";
import * as MyApps from "./Routes";
import "./shared/css/Form.css";

const AppOutlet = [
  <Route
    path="/app/find-resolution"
    component={MyApps.FindResolutionApp}
    exact
  />,
  <Route
    path="/app/find-aspect-ratio"
    component={MyApps.FindAspectRatioApp}
    exact
  />,
  <Route
    path="/app/custom-aspect-ratio"
    component={MyApps.CustomAspectRatioApp}
    exact
  />
]

export default AppOutlet;
