import { expand, pencil, tabletLandscapeOutline } from "ionicons/icons";

interface appListItem {
  name: string;
  label: string;
  tag: string;
  imageUrl?: string;
  svgIcon?: any;
  ionIcon?: string;
  ionIconSrc?: string;
  description?: string;
}

export const appListItems: appListItem[] = [
  {
    name: "find-resolution",
    label: "Find Resolution",
    tag: "tool",
    ionIcon: `${tabletLandscapeOutline}`,
    description: "Find resolution by knowing aspect ratio.",
  },
  {
    name: "find-aspect-ratio",
    label: "Find Aspect Ratio",
    tag: "tool",
    ionIcon: `${expand}`,
    description: "Find aspect ratio by knowing width and height.",
  },
  {
    name: "custom-aspect-ratio",
    label: "Custom Aspect Ratio",
    tag: "tool",
    ionIcon: `${pencil}`,
    description: "Discover more about a specific aspect ratio, including pixel density, physical dimensions, scaling tables, etc.",
  },
];

export let tagItems: string[] = [];
appListItems.forEach((item) => {
  if (item.tag) {
    const itemAr = item.tag.split(",");
    itemAr.forEach((tagItem) => {
      if (tagItems.indexOf(tagItem.toLowerCase().trim()) < 0) {
        tagItems.push(tagItem.toLowerCase());
      }
    });
  }
});
