namespace Logic {

  export const calculateDiagnal = (width: number, height: number): number => {
    let diagnal = 0;
    diagnal = Math.sqrt(width * width + height * height);
    return diagnal;
  };

  export const calculatePhysicalWidth = (inchDiagnal: number, asWidth: number, asHeight: number) => {
    let width = 0;
    width = asWidth * (inchDiagnal / Math.sqrt(asWidth * asWidth + asHeight * asHeight));
    return width;
  }

  export const calculatePhysicalHeight = (inchDiagnal: number, asWidth: number, asHeight: number) => {
    let height = 0;
    height = asHeight * (inchDiagnal / Math.sqrt(asWidth * asWidth + asHeight * asHeight));
    return height;
  }

  export const calculateAspectRatio = (
    width: number,
    height: number
  ): { width: number, height: number } => {
    let temp, divisor, left, right;

    const gcd = (a: number, b: number): number => {
      if (b === 0) return a;
      return gcd(b, a % b);
    };
    if (width === height) return { width: 1, height: 1 };

    if (+width < +height) {
      temp = width;
      width = height;
      height = temp;
    }

    divisor = gcd(+width, +height);

    if ("undefined" === typeof temp) {
      left = width / divisor;
      right = height / divisor;
    } else {
      left = height / divisor;
      right = width / divisor;
    }

    if (8 === left && 5 === right) {
      left = 16;
      right = 10;
    }

    return { width: left, height: right };
  };

  //Find Width by knowing Height, asW (Aspect Ratio Width) and asH
  export const calculateWidth = (height: number, asw: number, ash: number): number => {
    let width = 0;

    width = (height / ash) * asw;
    return width;
  }

  export const calculateHeight = (width: number, asw: number, ash: number): number => {
    let height = 0;

    height = (width / asw) * ash;
    return height;
  }

  export const calculatePPI = (width: number, height: number, inchDiagnal: number) => {
    return Math.sqrt(width * width + height * height) / inchDiagnal;
  }
}



export default Logic;
