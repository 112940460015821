import {
  IonContent,
  IonPage,
  IonRouterOutlet,
  useIonRouter,
} from "@ionic/react";
import "./Home.css";
import { Redirect, Route } from "react-router";
import Apps from "./Apps";
import AppOutlet from "../apps/AppOutlet";
import { App } from "@capacitor/app";
import AspectRatio from "./AspectRatio"

const Home: React.FC = () => {
  const ionRouter = useIonRouter();
  document.addEventListener("ionBackButton", (ev: any) => {
    ev.detail.register(-1, () => {
      if (!ionRouter.canGoBack()) {
        App.exitApp();
      }
    });
  });
  return (
    <IonPage>
      <IonContent>
        <IonRouterOutlet>
          <Route path="/apps/as/:asWidth/:asHeight" component={AspectRatio} />
          <Route
            path="/apps/as/:asWidth/:asHeight/:width"
            component={AspectRatio}
          />
          <Route path="/home" component={Apps} exact />
          <Route path="/" exact>
            <Redirect to="/home" />
          </Route>
          {AppOutlet}
        </IonRouterOutlet>
      </IonContent>
    </IonPage>
  );
};

export default Home;
