import { IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonMenuButton, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import React, { useRef } from "react";
import { home, settings } from "ionicons/icons";
import Settings from "../../../components/Settings";
import style from "./SharedHeader.module.css";

interface IProps {
  onRefresh?: () => void;
  title: string;
  subTitle?: string;
  imageUrl?: string;
  svgIcon?: any;
  ionIcon?: any;
  ionIconSrc?: string;
  imageIcon?: boolean;
}
const SharedHeader: React.FC<IProps> = (props) => {
  const modal = useRef<HTMLIonModalElement>(null);
  return (
    <>
      <IonHeader className="mainHeader">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>

          <div className={style.appHeader}>
            <div className={style.appHeaderContainer}>
              <div className={`${style.iconWrapper} ${props.imageIcon && "img"}`}>
                {props.ionIcon && <IonIcon icon={props.ionIcon} />}
                {props.ionIconSrc && <IonIcon src={props.ionIconSrc} />}
                {props.imageUrl && <img alt={props.title} src={props.imageUrl} />}
              </div>
              <div className={style.appHeaderTitle}>
                <h1>{props.title}</h1>
                {props.subTitle && <h2>{props.subTitle}</h2>}
              </div>
            </div>
          </div>
          <IonButtons slot="end">
            <IonButton shape="round" title="Settings" onClick={() => modal.current?.present()}>
              <IonIcon icon={settings} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonModal id="modal" ref={modal} initialBreakpoint={1}
        breakpoints={[0, 1]}>
        <Settings onClose={() => modal.current?.dismiss()} />
      </IonModal>
    </>
  );
};

export default SharedHeader;
