import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import classes from "./ResolutionBox..module.css";
import myUtil from "../../../helpers/cha-utils";

type IProps = {
  rWidth: number; // Resolution Width
  rHeight: number; // Resolution Height
  text?: string; // Any text
};

const ResolutionBox: React.FC<IProps> = (props) => {
  const { rWidth, rHeight } = props;
  const calculatePhysicalWidth = (
    inchDiagnal: number,
    asWidth: number,
    asHeight: number
  ) => {
    let width = 0;
    width =
      asWidth *
      (inchDiagnal / Math.sqrt(asWidth * asWidth + asHeight * asHeight));
    return width;
  };

  const calculatePhysicalHeight = (
    inchDiagnal: number,
    asWidth: number,
    asHeight: number
  ) => {
    let height = 0;
    height =
      asHeight *
      (inchDiagnal / Math.sqrt(asWidth * asWidth + asHeight * asHeight));
    return height;
  };

  const rDiagonal = Math.sqrt(rWidth * rWidth + rHeight * rHeight);

  let diagnalAngle = (Math.asin(rHeight / rDiagonal) * 180) / Math.PI;

  // console.log("SS: " + (rHeight / rDiagonal) * 180);
  // console.log("Res Diag: " + rDiagonal);
  // console.log("Res Height: " + rHeight);
  // console.log("Diagonal Deg: " + diagnalAngle);
  return (
    <div className={classes.tvBox}>
      <div
        className={classes.viewBox}
        style={{
          // width: "100%",
          // maxWidth: "90%",
          maxHeight: "250px",
          aspectRatio: rWidth + "/" + rHeight,
          position: "relative",
        }}
      >
        <div className={classes.viewBoxBackground}></div>
        <div
          className={classes.diagonalLine}
          style={{ transform: "rotate(-" + diagnalAngle + "deg)" }}
        ></div>

        <div
          className={classes.diagnalText}
          style={{
            transform: "rotate(-" + diagnalAngle + "deg) translate(-50%,-50%)",
          }}
        >
          {props.text}
        </div>

        <span
          style={{
            position: "absolute",
            bottom: 2,
            left: "50%",
            transform: "translate(-50%, 0)",
          }}
        >
          {myUtil.formatNumber(rWidth, 2)} px
        </span>
        <span
          style={{
            position: "absolute",
            right: 8,
            top: "50%",
            transform: "translate(50%, 0) rotate(-90deg)",
          }}
        >
          {myUtil.formatNumber(rHeight, 2)} px
        </span>
      </div>
    </div>
  );
};

export default ResolutionBox;
