import myUtil from "../../helpers/cha-utils";
import SunInterface from "../shared/SunInterface";

export interface IAspectRatioInfo {
  key: string /** slug of the device name */;
  name: string /** Full name of the device */;
  width: number /** Horizontal pixels*/;
  height: number /** Vertical pixels*/;
}
export const asInfos: IAspectRatioInfo[] = [
  {
    key: "16:9",
    name: "16:9 (HDTV, 4K TV, laptop, monitor)",
    width: 16,
    height: 9,
  },
  {
    key: "16:10",
    name: "16:10 (laptop, monitor)",
    width: 16,
    height: 10,
  },
  {
    key: "21:9",
    name: "21:9 (ulrawide)",
    width: 21,
    height: 9,
  },
  {
    key: "32:9",
    name: "32:9 (superwide)",
    width: 32,
    height: 9,
  },
  {
    key: "3:2",
    name: "3:2 (tablet, laptop, monitor)",
    width: 3,
    height: 2,
  },
  {
    key: "4:3",
    name: "4:3 (old TV & monitor, tablet)",
    width: 4,
    height: 3,
  },
  {
    key: "5:4",
    name: "5:4 (old TV, digital camera)",
    width: 5,
    height: 4,
  },
  {
    key: "1:1",
    name: "1:1 (square)",
    width: 1,
    height: 1,
  },
];

const shareUnits = [
  { key: "in", name: "Inch", multiplier: 1 },
  { key: "cm", name: "Centimeter", multiplier: 2.54 },
  { key: "mm", name: "Millimeter", multiplier: 25.4 },
];

namespace Logic {
  export const areaUnits = [
    { key: "in²", name: "Square Inch", multiplier: 1 },
    { key: "cm²", name: "Square Centimeter", multiplier: 6.4516 },
    { key: "mm²", name: "Square Millimeter", multiplier: 645.16 },
  ];
  export const dataDef = {
    diagonal: {
      input: "",
      show: "",
      value: 0,
      unit: "in",
      units: shareUnits,
    },
    width: { input: "", show: "", value: 0, unit: "px", units: [] },
    height: { input: "", show: "", value: 0, unit: "px", units: [] },
    asWidth: { input: "", show: "", value: 0, unit: "", units: [] },
    asHeight: { input: "", show: "", value: 0, unit: "", units: [] },
  };

  export const getDefaultData = (): typeof dataDef => {
    return JSON.parse(JSON.stringify(dataDef));
  };

  export const setDataItemValue = (
    dataItem: SunInterface.DataItem,
    value: number
  ) => {
    dataItem.value = value;
    if (dataItem.units && dataItem.units.length > 0) {
      const convertedValue = getConvertedValue(dataItem);
      dataItem.input = myUtil
        .prepareFloatingNumber(convertedValue, 5)
        .toString();
      dataItem.show = myUtil.formatNumber(convertedValue);
    } else {
      dataItem.input = value.toString();
      dataItem.show = myUtil.formatNumber(value);
    }
  };
  export const getNativeValue = (
    units: SunInterface.Units,
    fromUnit: string,
    fromValue: number
  ) => {
    const fromUnitItem = units.find((item) => item.key === fromUnit);
    if (fromUnitItem === units[0]) return fromValue;
    if (fromUnitItem) {
      return fromValue / fromUnitItem.multiplier;
    }
    return 0;
  };

  export const converValue = (
    units: SunInterface.Units,
    toUnit: string,
    fromValue: number
  ) => {
    const toUnitItem = units.find((item) => item.key === toUnit);
    // if(fromUnitItem === units[0]) return;
    if (toUnitItem) {
      return fromValue * toUnitItem.multiplier;
    }
    return 0;
  };

  export const getConvertedValue = (dataItem: SunInterface.DataItem) => {
    if (dataItem.units) {
      const toUnitItem = dataItem.units.find(
        (item) => item.key === dataItem.unit
      );
      if (toUnitItem) {
        return dataItem.value * toUnitItem.multiplier;
      }
    }
    return 0;
  };

  export const calculateMegaPixels = (width: number, height: number) => {
    let res = (width * height) / 1000000;
    return res;
  };

  export const calculateAspectRatio = (
    width: number,
    height: number
  ): { width: number; height: number } => {
    let temp, divisor, left, right;

    const gcd = (a: number, b: number): number => {
      if (b === 0) return a;
      return gcd(b, a % b);
    };
    if (width === height) return { width: 1, height: 1 };

    if (+width < +height) {
      temp = width;
      width = height;
      height = temp;
    }

    divisor = gcd(+width, +height);

    if ("undefined" === typeof temp) {
      left = width / divisor;
      right = height / divisor;
    } else {
      left = height / divisor;
      right = width / divisor;
    }

    if (8 === left && 5 === right) {
      left = 16;
      right = 10;
    }

    return { width: left, height: right };
  };

  export const calculateDiagnal = (width: number, height: number): number => {
    let diagnal = 0;
    diagnal = Math.sqrt(width * width + height * height);
    return diagnal;
  };
}
export default Logic;
