import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useRef } from "react";
import AppList from "../components/AppList";
import AppHeader from "../apps/shared/components/AppHeader";
import {
  settings,
  square,
  tabletLandscape,
  tabletPortrait,
} from "ionicons/icons";

import Settings from "../components/Settings";
import AdMobService from "../helpers/AdMobService";
import BrowserService from "../helpers/BrowserService";
import { ButtonGuide } from "../components/ButtonGuide";
import SharedHeader from "../apps/shared/components/SharedHeader";

const Apps: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);

  useIonViewWillEnter(() => {
    AdMobService.autoLoadBanner();
  });

  BrowserService.getPrimaryColor();

  return (
    <IonPage>
      {/* <IonHeader className="ion-no-border mainHeader">
        <IonToolbar className="transparent">
          <IonButtons slot="end">
            <IonButton onClick={() => modal.current?.present()}>
              <IonIcon icon={settings} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader> */}
      <SharedHeader
        title="Aspect Ratio"
        subTitle="Calculator"
        imageUrl="/assets/icons/icon512.png"
        imageIcon={true}
      />
      <IonModal id="modal" ref={modal}>
        <Settings onClose={() => modal.current?.dismiss()} />
      </IonModal>
      <IonContent fullscreen id="mainContent">
        {/* <AppHeader
          title="Aspect Ratio Calculator"
          imageUrl="/assets/icons/icon512.png"
          imageIcon={true}
        /> */}
        <div className="ion-padding"></div>
        <AppList />

        <IonGrid fixed>
          <IonRow>
            <IonCol>
              <IonCard className="ion-margin-top">
                <IonCardHeader color="primary">
                  <IonCardSubtitle>Shortcuts</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent className="ion-no-padding">
                  <IonList lines="full">
                    <IonItem>
                      <IonLabel>Square</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/apps/as/1/1">
                      <IonIcon icon={square} slot="start"></IonIcon>
                      <IonLabel>1:1 Aspect Ratio Calculator</IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Landscape</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/apps/as/3/2">
                      <IonIcon icon={tabletLandscape} slot="start"></IonIcon>
                      <IonLabel>3:2 Aspect Ratio Calculator</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/apps/as/4/3">
                      <IonIcon icon={tabletLandscape} slot="start"></IonIcon>
                      <IonLabel>4:3 Aspect Ratio Calculator</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/apps/as/5/4">
                      <IonIcon icon={tabletLandscape} slot="start"></IonIcon>
                      <IonLabel>5:4 Aspect Ratio Calculator</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/apps/as/16/9">
                      <IonIcon icon={tabletLandscape} slot="start"></IonIcon>
                      <IonLabel>16:9 Aspect Ratio Calculator</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/apps/as/16/10">
                      <IonIcon icon={tabletLandscape} slot="start"></IonIcon>
                      <IonLabel>16:10 Aspect Ratio Calculator</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/apps/as/21/9">
                      <IonIcon icon={tabletLandscape} slot="start"></IonIcon>
                      <IonLabel>21:9 Aspect Ratio Calculator</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/apps/as/32/9">
                      <IonIcon icon={tabletLandscape} slot="start"></IonIcon>
                      <IonLabel>32:9 Aspect Ratio Calculator</IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Portrait</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/apps/as/1/2">
                      <IonIcon icon={tabletPortrait} slot="start"></IonIcon>
                      <IonLabel>1:2 Aspect Ratio Calculator</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/apps/as/2/3">
                      <IonIcon icon={tabletPortrait} slot="start"></IonIcon>
                      <IonLabel>2:3 Aspect Ratio Calculator</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/apps/as/3/4">
                      <IonIcon icon={tabletPortrait} slot="start"></IonIcon>
                      <IonLabel>3:4 Aspect Ratio Calculator</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/apps/as/4/5">
                      <IonIcon icon={tabletPortrait} slot="start"></IonIcon>
                      <IonLabel>4:5 Aspect Ratio Calculator</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/apps/as/9/16" lines="none">
                      <IonIcon icon={tabletPortrait} slot="start"></IonIcon>
                      <IonLabel>9:16 Aspect Ratio Calculator</IonLabel>
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <ButtonGuide name="aspect-ratio" />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Apps;
