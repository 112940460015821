import { IonInput } from "@ionic/react";
import React, { HTMLAttributes } from "react";

interface IMyIonInputProps extends HTMLAttributes<HTMLIonInputElement> {
  cId: string;
  data: { input: string; show: string; value: number };
  [x: string]: any;
  onMyChange: (key: string, value: any) => void;
  onMyFocus?: () => void;
}

const MyIonInput: React.FC<IMyIonInputProps> = (props) => {
  const inputRef = React.useRef<HTMLIonInputElement>(null);

  let isActive = false;

  // Check if the current Input is active
  try {
    if (
      inputRef &&
      inputRef.current &&
      document.activeElement &&
      document.activeElement.id
    ) {
      isActive =
        inputRef.current?.getElementsByTagName("input")[0].id ===
        document.activeElement?.id;
    }
  } catch (ex) {}

  // Set input value and type accordingly.
  const inputType = isActive ? "number" : "text";
  const displayValue = isActive ? props.data.input : props.data.show;

  return (
    <IonInput
      ref={inputRef}
      id={props.cId}
      type={inputType}
      className="ion-text-right"
      placeholder="—"
      value={displayValue}
      autocomplete="off"
      onIonFocus={(e) => {
        e.target.type = "number";
        e.target.value = props.data.input;
        props.onMyFocus && props.onMyFocus();
      }}
      onIonBlur={(e) => {
        e.target.type = "text";
        setTimeout(() => (e.target.value = props.data.show), 10);
        // (e.target.value = props.data.show), 10;
      }}
      onIonInput={(e) => props.onMyChange(props.cId, e.detail.value)}
      {...props}
    ></IonInput>
  );
};

export default MyIonInput;
