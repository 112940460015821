import { RangeValue } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRange,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import {
  calculator,
  close,
  colorPalette,
  colorPaletteOutline,
  moon,
} from "ionicons/icons";
import style from "./Settings.module.css";
import { useState } from "react";
import myUtil from "../../helpers/cha-utils";

type Props = {
  decimalValue?: number;
  onRangeUpdated?: (value: RangeValue) => void;
  onClose?: () => void;
};

const Settings: React.FC<Props> = (props) => {
  const [darkMode, setDarkMode] = useState<boolean>(
    localStorage.getItem(myUtil.prefix + "dark-mode-status") === "true"
  );

  const [themeColor, setThemeColor] = useState<string>(
    myUtil.formatThemeName(
      localStorage.getItem(myUtil.prefix + "theme-color-class") ??
        "default-theme"
    )
  );

  const themeColorOptionsJSX = Object.entries(myUtil.themeColors).map(
    ([key, item]) => (
      <IonSelectOption key={key} value={key}>
        {myUtil.formatThemeName(key)}
      </IonSelectOption>
    )
  );

  const currentTheme =
    localStorage.getItem(myUtil.prefix + "theme-color-class") ??
    "default-theme";
  const themeColorButtonsJSX = Object.entries(myUtil.themeColors).map(
    ([key, item]) => (
      <IonButton
        key={key}
        className={item}
        onClick={() => handleThemeColorChange(key)}
      >
        <span style={{ width: "10px" }}>{key == currentTheme ? "✓" : ""}</span>
      </IonButton>
    )
  );

  const handleThemeColorChange = (theme: string) => {
    myUtil.setupTheme(theme);
    // console.log(e.detail.value);
    setThemeColor(myUtil.formatThemeName(theme));
  };

  // const themeColors = ["red", "blue", "pink"];
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => props.onClose && props.onClose()}>
              <IonIcon icon={close} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid fixed>
          <IonRow className="ion-justify-align-center">
            <IonCol>
              <IonCard>
                <IonCardHeader>Personalization</IonCardHeader>
                <IonCardContent>
                  <IonList lines="full" className="ion-no-padding">
                    {props.decimalValue !== undefined && (
                      <>
                        <IonItem lines="none">
                          <IonIcon slot="start" icon={calculator}></IonIcon>
                          <IonLabel>
                            Decimal digits: {props.decimalValue}
                          </IonLabel>
                        </IonItem>
                        <IonItem lines="full">
                          <IonRange
                            ticks={true}
                            snaps={true}
                            min={0}
                            max={14}
                            aria-label="Fraction digits"
                            value={props.decimalValue}
                            onIonKnobMoveEnd={({ detail }) =>
                              props.onRangeUpdated &&
                              props.onRangeUpdated(detail.value)
                            }
                          >
                            <IonLabel slot="start">0</IonLabel>
                            <IonLabel slot="end">14</IonLabel>
                          </IonRange>
                        </IonItem>
                      </>
                    )}

                    <IonItem lines="full">
                      <IonIcon slot="start" icon={moon}></IonIcon>
                      <IonToggle
                        id="themeToggle"
                        aria-label="Dark Mode"
                        checked={darkMode}
                        onIonChange={(e) => {
                          myUtil.toggleDarkTheme(e.detail.checked);
                          setDarkMode(e.detail.checked);
                        }}
                      >
                        Dark mode : <strong>{darkMode ? "On" : "Off"}</strong>
                      </IonToggle>
                    </IonItem>

                    <IonItem lines="none">
                      <IonIcon slot="start" icon={colorPalette}></IonIcon>
                      <IonLabel>Theme Color</IonLabel>
                    </IonItem>
                    <IonItem lines="none">
                      <div className={style.colorButtons} slot="end">
                        {themeColorButtonsJSX}
                      </div>
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};
export default Settings;
