import { IonButton, IonIcon } from "@ionic/react";
import React from "react";
import BrowserService from "../helpers/BrowserService";
import { book } from "ionicons/icons";

type Props = {
  name: string;
};
export const ButtonGuide: React.FC<Props> = (props) => {
  return (
    <div className="ion-text-right ion-padding">
      <IonButton onClick={() => BrowserService.showGuide(props.name)} size="small" fill="outline">
        <IonIcon icon={book} slot="start" /> User Guide
      </IonButton>
    </div>
  );
};
